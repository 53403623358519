import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllBlogs } from "../api";

const LatestBlogs = () => {
    const [lastThreeBlogs, setLastThreeBlogs] = useState([]);

    useEffect(() => {
        getAllBlogs().then((res) => {
            setLastThreeBlogs(res.reverse().slice(0, 3));
        });
    }, []);

    const buildPreviewText = (input) => {
        return input.replace(/(<([^>]+)>)/gi, "");
    };

    return (
        <>
            <div className="home-blog-container tiles-container">
                {lastThreeBlogs.map((blog, index) => {
                    return (
                        <div key={index} className="home-blog">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "start",
                                    marginBottom: 8,
                                }}
                            >
                                <img
                                    src={JSON.parse(blog.images)[0]}
                                    style={{
                                        width: "30%",
                                        aspectRatio: 1,
                                        borderRadius: 12,
                                        marginRight: 8,
                                        objectFit: "cover",
                                    }}
                                />
                                <div>
                                    <div className="home-blog-title">
                                        {blog.title}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: 12,
                                            color: "#6C6C6C",
                                            textAlign: "left",
                                            marginTop: 5,
                                        }}
                                    >
                                        Author: {blog.author}
                                    </div>
                                </div>
                            </div>
                            <div className="home-blog-text">
                                {buildPreviewText(blog.body)}
                            </div>
                            <Link
                                to={`/blogs/${blog.id}`}
                                style={{ textAlign: "center" }}
                            >
                                <button className="home-blog-button">
                                    Keep reading
                                </button>
                            </Link>
                        </div>
                    );
                })}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Link
                    to={"/blogs"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        textDecoration: "none",
                    }}
                >
                    <button
                        className="button"
                        style={{
                            backgroundColor: "#10958D",
                        }}
                    >
                        Explore all blogs
                    </button>
                </Link>
            </div>
        </>
    );
};

export default LatestBlogs;
