import { FormControl, FormLabel } from "@mui/joy";
import Input from "@mui/joy/Input";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import NumberPicker from "react-widgets/NumberPicker";
import "react-widgets/styles.css";
import housr_logo_small from "../Icons/housr_icon.svg";
import cities from "../json/gb.json";
import "./css/LeadTenant.css";
import "./css/Tenants.css";

export const LeadTenant = () => {
    let { state } = useLocation();
    const user =
        Object.keys(state?.user || {}).length !== 0 ? state?.user : null;

    const [searchParams] = useSearchParams();

    const [country, setCountry] = useState("GB");
    const [number, setNumber] = useState("");

    const [firstName, setFirstName] = useState(
        state?.leadTenantName ? state?.leadTenantName?.split(" ")[0] : ""
    );
    const [lastName, setLastName] = useState(
        state?.leadTenantName ? state?.leadTenantName?.split(" ")[1] : ""
    );
    const [city, setCity] = useState("");
    const [source, setSource] = useState(user ? user.source : "");
    const [sourceOther, setSourceOther] = useState("");
    const [email, setEmail] = useState(state?.leadTenantEmail ?? "");
    const [tenants, setTenants] = useState(
        state?.bedrooms ? parseInt(state.bedrooms) : 1
    );
    const [showAlert, setShowAlert] = useState(false);
    const [alertHeading, setAlertHeading] = useState("");
    const [alertBody, setAlertBody] = useState("");
    const [error, setError] = useState("");

    const referral = searchParams.get("referral");

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setUser();
        }
        cities.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            return 1;
        });
    }, []);

    const setUser = () => {
        setFirstName(user?.name?.split(" ")[0]);
        setLastName(user?.name?.split(" ")[1]);
        setCity(user.city);
        setEmail(user.email);
        setTenants(user.bedrooms);
        setNumber(user.contact_no);
    };

    const submitForm = () => {
        if (firstName === "" || lastName === "") {
            setAlertHeading("Error with your name");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your full name has not been completely filled in yet!"
            );
            setShowAlert(true);
        } else if (city === "") {
            setAlertHeading("Error with your city");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your city has not been chosen!"
            );
            setShowAlert(true);
        } else if (email === "") {
            setAlertHeading("Error with your email");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your email has not been filled in yet!"
            );
            setShowAlert(true);
        } else if (number === "") {
            setAlertHeading("Error with your number");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your number has not been filled in yet!"
            );
            setShowAlert(true);
        } else if (
            country === "GB" &&
            (number.length !== 13 || number[3] !== "7")
        ) {
            setAlertHeading("Error with your phone number");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your phone number is not valid!"
            );
            setShowAlert(true);
        } else if (!tenants) {
            setAlertHeading("Error with your number of tenants");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your number of tenants has not been filled in!"
            );
            setShowAlert(true);
        } else {
            setError("");

            getQuote();
        }
    };

    const getQuote = () => {
        let url = `/signup/bill-quotes/${tenants}`;

        if (referral) {
            url += `?referral=${referral}`;
        }

        if (user) {
            console.log(user);
            axios
                .post("/leadTenant/update", {
                    number: number,
                    name: firstName + " " + lastName,
                    city: city,
                    email: email,
                    tenants: tenants,
                    uuid: user.uuid,
                })
                .then((res) => {
                    if (res.status === 200) {
                        navigate(url, {
                            state: {
                                leadTenantId: user.id,
                                leadTenantNumber: number,
                                leadTenantName: firstName + " " + lastName,
                                city,
                            },
                        });
                    }
                })
                .catch((e) => {
                    console.error("Error: ", e);
                    setAlertHeading("Error with your phone number");
                    setAlertBody(e.response?.data);
                    setShowAlert(true);
                });
        } else {
            let trueSource = source === "Other" ? sourceOther : source;

            let url = `/signup/bill-quotes/${tenants}`;

            if (referral) {
                url += `?referral=${referral}`;
            }

            axios
                .post("/leadTenant", {
                    number: number,
                    name: firstName + " " + lastName,
                    city: city,
                    email: email,
                    tenants: tenants,
                    source: trueSource,
                })
                .then((res) => {
                    window.dataLayer.push({
                        event: "bills quote received",
                        eventProps: {
                            category: "bills",
                            action: "quote received",
                            label: "bills quote received",
                            value: firstName + " " + lastName,
                        },
                    });
                    if (res.status === 200) {
                        navigate(url, {
                            state: {
                                leadTenantId: res.data?.lastInsertedId,
                                leadTenantNumber: number,
                                leadTenantName: firstName + " " + lastName,
                                leadTenantEmail: email,
                                hubSpotContactIds: res.data?.hubSpotContactIds,
                                city,
                            },
                        });
                    }
                })
                .catch((e) => {
                    console.error("Error: ", e);
                    setAlertHeading("Error");
                    setAlertBody(e.response?.data);
                    setShowAlert(true);
                });
        }
    };

    const AlertValidation = () => {
        return (
            <Alert
                show={showAlert}
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
                className="error-alert"
            >
                <Alert.Heading>{alertHeading}</Alert.Heading>
                <p>{alertBody}</p>
            </Alert>
        );
    };

    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                minHeight: "100vh",
                backgroundColor: "#f8f9fa",
            }}
        >
            <div
                className="bg-white p-4 card-width justify-content-center d-flex"
                style={{
                    borderRadius: "12px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                }}
            >
                <Form className="lead-tenant-signup-form my-auto">
                    <div className="housr-logo">
                        <img
                            src={housr_logo_small}
                            style={{ height: "4vh" }}
                            alt="logo"
                        />
                    </div>
                    <h1 className="d-flex justify-content-center signup-header my-3">
                        Get a Bills Bundle Quote
                    </h1>
                    <AlertValidation />
                    <Form.Group>
                        <InputGroup hasValidation className="name-boxes">
                            <FormControl className="single-name-box">
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstName(
                                            e.target.value
                                                .charAt(0)
                                                .toUpperCase() +
                                                e.target.value.slice(1)
                                        );
                                    }}
                                    variant="outlined"
                                />
                            </FormControl>
                            <FormControl className="single-name-box last-name-margin">
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    value={lastName}
                                    onChange={(e) =>
                                        setLastName(
                                            e.target.value
                                                .charAt(0)
                                                .toUpperCase() +
                                                e.target.value.slice(1)
                                        )
                                    }
                                    variant="outlined"
                                />
                            </FormControl>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <FormControl>
                            <FormLabel>Email Address</FormLabel>
                            <Input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                type="email"
                            />
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <FormLabel>Choose City</FormLabel>
                        <Select
                            placeholder=""
                            options={cities}
                            value={{ value: city, label: city }}
                            onChange={(option) => setCity(option.value)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: "#fbfcfe",
                                    boxShadow:
                                        "var(--joy-shadowRing, 0 0 #000), 0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))",
                                    borderColor: "rgb(205, 215, 225)",
                                    borderWidth: "1px",
                                    borderRadius: "6px",
                                }),
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <FormLabel>Number of Tenants</FormLabel>
                        <div>
                            <NumberPicker
                                value={tenants}
                                max={12}
                                min={1}
                                onChange={(num) => {
                                    setTenants(num);
                                }}
                                style={{
                                    backgroundColor: "#fbfcfe",
                                    boxShadow:
                                        "var(--joy-shadowRing, 0 0 #000), 0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))",
                                    borderColor: "rgb(205, 215, 225)",
                                    borderWidth: "1px",
                                    borderRadius: "6px",
                                }}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <FormLabel>Phone Number</FormLabel>
                        <div>
                            <PhoneInput
                                placeholder="Enter phone number"
                                withCountryCallingCode
                                defaultCountry={country}
                                onCountryChange={setCountry}
                                value={number}
                                onChange={(num) => {
                                    setNumber(num);
                                }}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <FormLabel>How did you hear about us?</FormLabel>
                        <Select
                            placeholder=""
                            value={
                                source ? { value: source, label: source } : ""
                            }
                            options={[
                                { value: "Referral", label: "Referral" },
                                { value: "Google", label: "Google" },
                                {
                                    value: "Social media",
                                    label: "Social media",
                                },
                                {
                                    value: "Word of mouth",
                                    label: "Word of mouth",
                                },
                                { value: "Flyer/Event", label: "Flyer/Event" },
                                { value: "Other", label: "Other" },
                            ]}
                            onChange={(option) => setSource(option.value)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: "#fbfcfe",
                                    boxShadow:
                                        "var(--joy-shadowRing, 0 0 #000), 0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))",
                                    borderColor: "rgb(205, 215, 225)",
                                    borderWidth: "1px",
                                    borderRadius: "6px",
                                }),
                            }}
                        />
                        {source === "Other" && (
                            <InputGroup>
                                <Form.Control
                                    placeholder="Please specify where you heard about us"
                                    className="mt-2"
                                    type="text"
                                    onChange={(e) =>
                                        setSourceOther(e.target.value)
                                    }
                                />
                            </InputGroup>
                        )}
                    </Form.Group>
                    <div className="mb-lg-2 mb-1">
                        <Button
                            className="get-quote-button w-100"
                            onClick={() => submitForm()}
                        >
                            Get Quote
                        </Button>
                    </div>
                    <p style={{ fontSize: 11 }}>
                        By completing this form you consent to being contacted
                        by the Housr Bills team regarding your quote.
                    </p>
                </Form>
                {error && (
                    <div
                        className="mt-3 bg-danger py-3"
                        style={{ color: "white", height: "10%", width: "50%" }}
                    >
                        <h5>Error</h5>
                        <span>{error}</span>
                    </div>
                )}
            </div>
        </div>
    );
};
