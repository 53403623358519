import axios from "axios";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import house from "../Icons/house_3.png";
import house_sign_in from "../Icons/house_sign_in.png";
import housrlogo from "../Icons/housr_icon.svg";
import key from "../Icons/key.png";
import cities from "../json/gb.json";
import { useAuth } from "../Providers/AuthProvider";
import "./css/ReferralsSignUp.css";
import { Faqs } from "./Faqs";
import { PageCard } from "./PageCard";
import { ReferralTermsAndConditions } from "./ReferralTermsAndConditions";

const ReferralsSignUp = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [login, setLogin] = useState(true);
  const [validated, setValidated] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [alert, setAlert] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [country, setCountry] = useState("GB");
  const [showTC, setShowTC] = useState(false);

  useEffect(() => {
    auth
      .checkAuth()
      .then(() => navigate("/dashboard"))
      .catch(() => {
        // Stay on login page
      });

    cities.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      return 1;
    });
  }, [auth, navigate]);

  const [signupData, setSignupData] = useState({
    firstName: null,
    surname: null,
    email: null,
    number: null,
    password: null,
    confirmPassword: null,
  });

  const submitSignUp = (event) => {
    setValidated(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() && passwordsMatch && passwordValid) {
      if (
        country === "GB" &&
        (signupData.number?.length !== 13 || signupData.number[3] !== 7)
      ) {
        setAlert("Please enter a valid phone number.");
        setShowAlert(true);
        return;
      }
      auth
        .signup(signupData)
        .then(() => {
          navigate("/dashboard", {state: {signup: true}});
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            setAlert(
              `An account already exists with the ${error.response.data.reason} you entered.`
            );
            setShowAlert(true);
          }
        });
    }
  };

  const TermsConditionsModal = () => {
    return (
      <Modal
        show={showTC}
        onHide={() => {
          setShowTC(false);
        }}
        className="d-flex flex-column"
      >
        <ReferralTermsAndConditions />
      </Modal>
    );
  };

  return login ? (
    <LoginForm setLogin={setLogin} />
  ) : (
    <PageCard
      style={{
        width: "80%",
        height: "90vh",
        borderRadius: "1rem",
        boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
        alignItems: "center",
        display: "flex",
        overflowY: "scroll",
        zIndex: "10",
      }}
      signUp
    >
      <TermsConditionsModal />
      <div className="my-auto container align-items-center d-flex flex-column">
        <div
          className="row h-25 justify-content-start container-sign-up pt-lg-5"
          style={{ width: "90%" }}
        >
          <div
            className="d-flex flex-column justify-content-end col heading-sign-up"
            style={{ width: "35%" }}
          >
            <img
              src={housrlogo}
              alt="housr_logo"
              style={{ width: "8%" }}
              className="mx-auto mb-3 housr-logo-sign-in"
            />

            <h1
              className="fw-bold mb-5 title-sign-in"
              style={{ fontSize: "36px" }}
            >
              Create an account
            </h1>
            <Alert
              show={showAlert}
              variant="danger"
              onClose={() => setShowAlert(false)}
              dismissible
            >
              <Alert.Heading>Error</Alert.Heading>
              <p>{alert}</p>
            </Alert>
          </div>
          <div className="col d-none d-lg-block"></div>
        </div>
        <Form
          noValidate
          validated={validated}
          className="my-auto row justify-content-around h-75 sign-up-form"
          onSubmit={submitSignUp}
          style={{ width: "90%" }}
        >
          <div
            className="col align-self-start justify-content-start"
            style={{ width: "35%" }}
          >
            <div className="d-flex flex-column mx-auto">
              <Form.Group>
                <Form.Label className="justify-content-start w-100 d-flex ps-2 fw-bold">
                  First Name
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    required
                    className="mb-2"
                    onChange={(event) =>
                      setSignupData({
                        ...signupData,
                        firstName: event.target.value,
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your first name
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Label className="justify-content-start w-100 d-flex ps-2 fw-bold">
                  Last Name
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    required
                    className="mb-2"
                    onChange={(event) =>
                      setSignupData({
                        ...signupData,
                        surname: event.target.value,
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your last name
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Label className="justify-content-start w-100 d-flex ps-2 fw-bold">
                  Email Address
                </Form.Label>
                <Form.Control
                  required
                  className="mb-2"
                  type="email"
                  onChange={(event) =>
                    setSignupData({ ...signupData, email: event.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="justify-content-start w-100 d-flex ps-2 fw-bold">
                  Phone Number
                </Form.Label>
                <div className="mb-2 mx-auto w-100 ps-2">
                  <PhoneInput
                    placeholder="Enter phone number"
                    withCountryCallingCode
                    defaultCountry={country}
                    onCountryChange={setCountry}
                    value={signupData.number}
                    onChange={(number) =>
                      setSignupData({ ...signupData, number: number })
                    }
                  />
                </div>
              </Form.Group>
              
            </div>
            <div className="d-none d-lg-block py-3">
              <img alt="house" src={house} style={{ height: "15vh", marginTop: "6vh" }} />
            </div>
            <div className="d-lg-none">
              <Form.Group className="mb-4">
                <Form.Label className="justify-content-start w-100 d-flex ps-2 fw-bold">
                  Password
                </Form.Label>
                <Form.Control
                  required
                  style={{
                    backgroundImage: "none",
                    border: "1px solid #dee2e6",
                    boxShadow: "none",
                  }}
                  className="mb-2"
                  type="password"
                  value={signupData.password}
                  onChange={(event) => {
                    const password = event.target.value;
                    setSignupData({
                      ...signupData,
                      password,
                    });
                    setPasswordsMatch(password === signupData.confirmPassword);
                    setPasswordValid(
                      password.length > 8 && /[A-Z]/.test(password)
                    );
                  }}
                />
                <Form.Label className="justify-content-start w-100 d-flex ps-2 fw-bold">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  required
                  style={{
                    backgroundImage: "none",
                    border: "1px solid #dee2e6",
                    boxShadow: "none",
                  }}
                  type="password"
                  value={signupData.confirmPassword}
                  onChange={(event) => {
                    setSignupData({
                      ...signupData,
                      confirmPassword: event.target.value,
                    });
                    setPasswordsMatch(
                      event.target.value === signupData.password
                    );
                  }}
                />
                <Form.Control.Feedback
                  className={
                    validated && !passwordsMatch ? "d-block" : "d-none"
                  }
                  type="invalid"
                >
                  Passwords do not match
                </Form.Control.Feedback>
                <Form.Control.Feedback
                  className={validated && !passwordValid ? "d-block" : "d-none"}
                  type="invalid"
                >
                  Passwords must be at least 8 characters and contain at least
                  one upper case character.
                </Form.Control.Feedback>
              </Form.Group>
              <div className="buttons-container-sign-up">
                <Button
                  type="submit"
                  className="mb-3 w-100 fw-bold buttons-sign-up"
                  style={{
                    backgroundColor: "#109539",
                    borderColor: "#109539",
                    borderRadius: "20px",
                  }}
                >
                  Confirm
                </Button>
                <Button
                  className="w-100 fw-bold buttons-sign-up"
                  style={{
                    backgroundColor: "white",
                    borderColor: "#109539",
                    borderRadius: "20px",
                    color: "#109539",
                  }}
                  onClick={() => setLogin(true)}
                >
                  Already have an account?
                </Button>
                <div className="mt-3 w-75 mx-auto">
                  <span className="text-start">
                    By creating an account, you agree to the{" "}
                    <a
                      style={{ color: "#109539", cursor: "pointer" }}
                      onClick={() => {
                        setShowTC(true);
                      }}
                    >
                      Terms & Conditions.
                    </a>
                  </span>
                </div>
              </div>
              <Faqs/>
            </div>
          </div>
          <div
            className="col align-self-start h-75 d-none d-lg-block"
            style={{ width: "35%" }}
          >
            <div className="w-100 d-flex flex-column mx-auto">
              <Form.Group className="mb-4">
                <Form.Label className="justify-content-start w-100 d-flex ps-2 fw-bold">
                  Password
                </Form.Label>
                <Form.Control
                  style={{
                    backgroundImage: "none",
                    border: "1px solid #dee2e6",
                    boxShadow: "none",
                  }}
                  required
                  className="mb-2"
                  type="password"
                  placeholder="Password"
                  value={signupData.password}
                  onChange={(event) => {
                    const password = event.target.value;
                    setSignupData({
                      ...signupData,
                      password,
                    });
                    setPasswordsMatch(password === signupData.confirmPassword);
                    setPasswordValid(
                      password.length > 7 && /[A-Z]/.test(password)
                    );
                  }}
                />
                <Form.Label className="justify-content-start w-100 d-flex ps-2 fw-bold">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  style={{
                    backgroundImage: "none",
                    border: "1px solid #dee2e6",
                    boxShadow: "none",
                  }}
                  required
                  type="password"
                  placeholder="Confirm Password"
                  value={signupData.confirmPassword}
                  onChange={(event) => {
                    setSignupData({
                      ...signupData,
                      confirmPassword: event.target.value,
                    });
                    setPasswordsMatch(
                      event.target.value === signupData.password
                    );
                  }}
                />
                <Form.Control.Feedback
                  className={
                    validated && !passwordsMatch ? "d-block" : "d-none"
                  }
                  type="invalid"
                >
                  Passwords do not match
                </Form.Control.Feedback>
                <Form.Control.Feedback
                  className={validated && !passwordValid ? "d-block" : "d-none"}
                  type="invalid"
                >
                  Passwords must be at least 8 characters and contain at least
                  one upper case character.
                </Form.Control.Feedback>
              </Form.Group>
              <div className="buttons-container-sign-up">
                <Button
                  type="submit"
                  className="mb-3 w-100 fw-bold buttons-sign-up"
                  style={{
                    backgroundColor: "#109539",
                    borderColor: "#109539",
                    borderRadius: "20px",
                  }}
                >
                  Confirm
                </Button>
                <Button
                  className="w-100 fw-bold buttons-sign-up"
                  style={{
                    backgroundColor: "white",
                    borderColor: "#109539",
                    borderRadius: "20px",
                    color: "#109539",
                  }}
                  onClick={() => setLogin(true)}
                >
                  Already have an account?
                </Button>
                <div className="mt-3 w-75 mx-auto">
                  <span className="text-start">
                    By creating an account, you agree to the{" "}
                    <a
                      style={{ color: "#109539", cursor: "pointer" }}
                      onClick={() => {
                        setShowTC(true);
                      }}
                    >
                      Terms & Conditions.
                    </a>
                  </span>
                </div>
              </div>
                <Faqs />
            </div>
          </div>
        </Form>
      </div>
    </PageCard>
  );
};

const LoginForm = ({ setLogin }) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("danger");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const login = () => {
    auth
      .login({ email, password })
      .then((response) => {
        navigate("/dashboard");
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setAlert("Invalid credentials");
        } else {
          setAlert("Something went wrong, please try again.");
        }
        setAlertType("danger");
        setShowAlert(true);
      });
  };

  return (
    <div className="w-100 m-auto" style={{ overflow: "hidden" }}>
      <ForgotPasswordModal
        visible={showForgotPasswordModal}
        setVisible={setShowForgotPasswordModal}
        setAlert={setAlert}
        setAlertType={setAlertType}
        setShowAlert={setShowAlert}
      />

      <div
        style={{ position: "relative", zIndex: 99, top: "70vh", left: "-25vw" }}
        className="key-container"
      >
        <img
          src={key}
          alt="key"
          style={{ position: "absolute", height: "15vh" }}
          className="key-image"
        />
      </div>
      <div
        style={{
          position: "relative",
          zIndex: 99,
          bottom: "-60vh",
          right: "-20vw",
        }}
        className="house-sign-in-container"
      >
        <img
          src={house_sign_in}
          alt="house_sign_in"
          style={{ position: "absolute", height: "35vh" }}
          className="house-sign-in"
        />
      </div>

      <PageCard
        style={{
          width: "32%",
          height: "80%",
          overflowY: "scroll",
          borderRadius: "1rem",
          boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
          alignItems: "center",
          display: "flex",
        }}
        signIn
      >
        <div className="d-flex flex-column justify-content-center w-100">
          <img
            src={housrlogo}
            alt="housr_logo"
            style={{ width: "8%" }}
            className="mx-auto mb-3 housr-logo-sign-in"
          />
          <Alert
            show={showAlert}
            variant={alertType}
            onClose={() => setShowAlert(false)}
            dismissible
            className="w-75 mx-auto"
          >
            <Alert.Heading style={{ fontSize: "18px" }} className="my-auto">
              {alert}
            </Alert.Heading>
          </Alert>
          <h1
            className="fw-bold mb-5 title-sign-in"
            style={{ fontSize: "36px" }}
          >
            Sign into Housr Bills
          </h1>
          <Form.Group className="mx-auto sign-in-form" style={{ width: "70%" }}>
            <Form.Label className="justify-content-start d-flex ms-1">
              Email
            </Form.Label>
            <Form.Control
              className="mb-3"
              onChange={(event) => {
                setEmail(event.target.value);
                setShowAlert(false);
              }}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mx-auto sign-in-form" style={{ width: "70%" }}>
            <div className="d-flex flex-row justify-content-between ms-1 pb-1">
              <Form.Label className="my-0">Password</Form.Label>
            </div>
            <Form.Control
              className="mb-3"
              type="password"
              onChange={(event) => {
                setShowAlert(false);
                setPassword(event.target.value);
              }}
            ></Form.Control>
            <div className="d-flex flex-row justify-content-end ms-1 pb-1">
              <Button
                style={{
                  color: "#109539",
                  backgroundColor: "transparent",
                  borderWidth: "0px",
                }}
                className="px-0 py-0"
                onClick={() => setShowForgotPasswordModal(true)}
              >
                Forgot your password?
              </Button>
            </div>
          </Form.Group>
          <Form.Group
            style={{ width: "70%", height: "15vh" }}
            className="mx-auto sign-in-form align-items-center d-flex"
          >
            <div className="mx-auto w-100">
              <Button
                className="me-4 w-100 py-2 fw-bold"
                style={{
                  borderRadius: "20px",
                  backgroundColor: "#109539",
                  fontSize: "18px",
                  borderColor: "#109539",
                  position: "relative",
                  zIndex: 2,
                }}
                onClick={login}
              >
                Continue
              </Button>
            </div>
          </Form.Group>

          <Form.Group
            className="d-flex justify-content-start mx-auto align-items-center sign-in-form"
            style={{ width: "70%", zIndex: "2" }}
          >
            Don't have an account?
            <Button
              className="px-0 my-auto ms-1 py-0"
              style={{
                color: "#109539",
                fontSize: "16px",
                backgroundColor: "transparent",
                borderWidth: "0px",
              }}
              onClick={() => setLogin(false)}
            >
              Sign up here!
            </Button>
          </Form.Group>
        </div>
      </PageCard>
    </div>
  );
};

export const ForgotPasswordModal = ({
  visible,
  setVisible,
  setAlert,
  setAlertType,
  setShowAlert,
}) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [expectedOtp, setExpectedOtp] = useState();
  const [otp, setOtp] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [userId, setUserId] = useState();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [userError, setUserError] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (resendTimer > 0 && !success) {
        setResendTimer(resendTimer - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [resendTimer, success]);

  const forgotPassword = async () => {
    try {
      setLoading(true);
      const response = await axios.post("/auth/forgotPassword", { email });
      const otp = response.data.otp;
      setUserId(response.data.user.id);
      setLoading(false);
      setExpectedOtp(otp);
      setResendTimer(15);
    } catch (error) {
      setLoading(false);
      setUserError(true);
    }
  };

  const submitOtp = () => {
    setError(false);
    setSubmitted(true);
    axios
      .post("/auth/verifyOtp", { otp, userId })
      .then((response) => {
        setSuccess(true);
        setExpectedOtp(false);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setSubmitted(false);
      });
  };

  const resend = async () => {
    setSubmitted(true);
    const response = await axios.post("/auth/forgotPassword", { email });
    const otp = response.data.otp;
    setExpectedOtp(otp);
    setSubmitted(false);
    setResendTimer(15);
  };

  const ResetPassword = ({ userId, setAlert, setAlertType, setShowAlert }) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [disabled, setDisabled] = useState(false);

    const submit = async () => {
      if (!password) {
        setError("Please enter a new password.");
      }
      if (password !== confirmPassword) {
        setError("Passwords do not match!");
        return;
      }
      if (password.length < 8 || !/[A-Z]/.test(password)) {
        setError(
          "Passwords must be at least 8 characters and contain at least one upper case character!"
        );
        return;
      }
      setDisabled(true);
      try {
        await axios.post("/auth/resetPassword", { userId, password });
        setVisible(false);
        setAlertType("success");
        setAlert("Successfully reset password!");
        setShowAlert(true);
      } catch (error) {}
      setDisabled(false);
    };

    return (
      <div>
        <h5>Reset Password</h5>
        <Form.Control
          type="password"
          onChange={(event) => {
            setError(false);
            setPassword(event.target.value);
          }}
          placeholder="New Password"
          className="mt-3 mb-3"
        ></Form.Control>
        <Form.Control
          type="password"
          onChange={(event) => {
            setError(false);
            setConfirmPassword(event.target.value);
          }}
          placeholder="Confirm Password"
          className="w-100 me-2"
        ></Form.Control>
        {error && <div className="ms-2 mt-2 text-danger">{error}</div>}
        <div className="w-100 d-flex justify-content-center">
          <Button
            disabled={disabled}
            onClick={submit}
            className="mt-3 modal-button"
          >
            Reset
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={visible}
      onHide={() => setVisible(false)}
      className="d-flex flex-column justify-content-center"
    >
      <Modal.Header closeButton>
        <Modal.Title>Forgot Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!expectedOtp && !success && (
          <>
            <div>
              Enter your email address below to receive a one-time passcode to
              recover your password.
            </div>
            {userError && (
              <Alert className="m-3" variant="danger">
                No user account found for the email entered.
              </Alert>
            )}
            <div className="d-flex mt-3 justify-content-center">
              <Form.Control
                className="w-75 me-2"
                placeholder="Email Address"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <Button onClick={forgotPassword} className="modal-button">
                Go
              </Button>
            </div>
          </>
        )}
        {loading && (
          <div className="w-100 d-flex justify-content-center mt-4">
            <Spinner />
          </div>
        )}
        {expectedOtp && (
          <div>
            <h5>Enter Otp</h5>
            <div>
              We have sent a one-time passcode to the phone number associated to
              your account.
            </div>
            <div className="w-100 my-3" style={{ height: "4.5vh" }}>
              <OtpInput
                value={otp}
                onChange={(otp) => setOtp(otp)}
                numInputs={6}
                containerStyle={{
                  width: "90%",
                  margin: "auto",
                  height: "100%",
                  justifyContent: "space-between",
                }}
                inputStyle={{
                  width: "30px",
                  borderRadius: 5,
                  borderWidth: 1,
                  aspectRatio: 1,
                  boxShadow: "1px 1px 3px #00000033",
                }}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            {error && (
              <div className="text-danger mb-3">OTP entered was incorrect!</div>
            )}
            <div className="d-flex justify-content-around w-75 m-auto">
              <Button
                className="modal-button"
                disabled={submitted || resendTimer > 0}
                onClick={resend}
              >
                {resendTimer < 1 ? "Resend" : `Resend in ${resendTimer}`}
              </Button>
              <Button
                className="modal-button"
                disabled={submitted || otp.length < 6}
                onClick={submitOtp}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
        {success && (
          <ResetPassword
            userId={userId}
            setAlert={setAlert}
            setAlertType={setAlertType}
            setShowAlert={setShowAlert}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ReferralsSignUp;
