import { Option, Select } from "@mui/joy";
import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import {
    Link,
    useLocation,
    useParams,
    useSearchParams,
} from "react-router-dom";
import contents from "../Icons/Contents.svg";
import lightning from "../Icons/Electric.svg";
import gas from "../Icons/gas.svg";
import water from "../Icons/water.svg";
import wifi from "../Icons/Wifi.svg";
import { getBillPrice, getQuoteRetrieval } from "../api";
import { checkScottishCity } from "../lib/utils";
import "./css/QuoteDisplay.css";
import "./css/LeadTenant.css";
import { PriceSlash } from "./PriceSlash";
import Lottie from "react-lottie";
import loadingAnimation from "../json/loader.json";

export const QuoteDisplay = () => {
    const { bedrooms } = useParams();
    let { state } = useLocation();

    const leadTenantId = state?.leadTenantId;
    const leadTenantNumber = state?.leadTenantNumber;
    const leadTenantName = state?.leadTenantName;
    const leadTenantEmail = state?.leadTenantEmail;
    const discount = state?.discount;
    const flatmateData = state?.flatmateData;
    const hubSpotContactIds = state?.hubSpotContactIds;
    const city = state?.city;

    const isScottishCity = checkScottishCity(city);

    const [searchParams] = useSearchParams();
    const uuid = searchParams.get("uuid");
    const [billPriceUnlimited, setBillPriceUnlimited] = useState("");
    const [billPriceUnlimitedDaily, setBillPriceUnlimitedDaily] = useState("");
    const [displayDaily, setDisplayDaily] = useState(true);
    const [billPriceUnlimitedDiscounted, setBillPriceUnlimitedDiscounted] =
        useState(null);
    const [discountMultiplier, setDiscountMultiplier] = useState(null);
    const [user, setUser] = useState({});
    const [slashedPrice, setSlashedPrice] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (uuid) {
            getQuoteRetrieval(uuid).then((response) => {
                setUser(response);
                setDiscountMultiplier(response.discountMultiplier);
                getBillPrice(user.bedrooms)
                    .then((res) => {
                        if (response.discount_code) {
                            setBillPriceUnlimitedDiscounted(
                                (
                                    res.price?.[0][0]?.price *
                                    response.discountMultiplier
                                ).toFixed(2)
                            );
                        }
                        setBillPriceUnlimited(
                            parseFloat(res.price?.[0][0]?.price).toFixed(2)
                        );
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });
        } else {
            getBillPrice(bedrooms, discount, city).then((res) => {
                const price = res.price?.[0][0]?.price;
                const priceSlashed = city === "Leeds" ? (res.price?.[0][0]?.price_discounted - 3).toFixed(2) : res.price?.[0][0]?.price_discounted?.toFixed(2);

                setDiscountMultiplier(res.discount);
                if (res.discount) {
                    setBillPriceUnlimitedDiscounted(
                        (price * res.discount).toFixed(2)
                    );

                    setSlashedPrice((currentSlashedPrice) => {
                        return {
                            ...currentSlashedPrice,
                            discountedPrice: (
                                priceSlashed * res.discount
                            ).toFixed(2),
                            dailyDiscountedPrice: (priceSlashed * 52 / 7).toFixed(2)
                        };
                    });
                }
                setBillPriceUnlimited(
                    parseFloat(city === "Leeds" ? price - 3 : price).toFixed(2)
                );
                setBillPriceUnlimitedDaily(
                    parseFloat(res.price?.[0][0]?.price / 7).toFixed(2)
                );
                setSlashedPrice((currentSlashedPrice) => {
                    return { ...currentSlashedPrice, price: priceSlashed, dailyPrice: (priceSlashed * (52 / 365) ).toFixed(2) };
                });
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [uuid, user.bedrooms, bedrooms, billPriceUnlimited, city, discount]);

    const referral = searchParams.get("referral");
    let url = `/signup/tenants`;

    if (referral) {
        url += `?referral=${referral}`;
    }

    function handleFrequencyChange(e, newValue) {
        let bool = newValue === "false";
        setDisplayDaily(!bool);
    }

    return isNaN(billPriceUnlimited) || isNaN(billPriceUnlimitedDiscounted) ? (
        <div
            className="d-flex justify-content-center flex-column align-items-center w-100 border"
            style={{ height: "100vh" }}
        >
            <h3>Fetching your bill quote...</h3>
            <Spinner />
        </div>
    ) : (
        <div style={{ backgroundColor: "#f8f9fa", height: "100vh" }}>
            <div className="d-flex justify-content-center">
                <div
                    className="d-flex flex-column py-5 align-items-center justify-content-evenly"
                    style={{ height: "100vh" }}
                >
                    <div className="justify-content-center align-items-center d-flex flex-column">
                        <h2 className="fw-bold">Your Quote</h2>
                        <div className="d-flex flex-row w-100 justify-content-center">
                            <p className="justify-content-center">
                                For {bedrooms}{" "}
                                {bedrooms > 1 ? "housemates" : "housemate"}
                            </p>
                        </div>
                    </div>
                    <div className="option-buttons">
                        <div className="quote-card-selection" tabIndex="0">
                            <div>
                                <h3 className="package-name">
                                    Housr Unlimited
                                </h3>
                            </div>
                            <div
                                className="border-bottom pricing-container"
                                style={{ width: "85%" }}
                            >
                                {loading ? (
                                    <div className="lottie-container">
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: loadingAnimation,
                                            }}
                                            width={"15%"}
                                        />
                                    </div>
                                ) :  slashedPrice.price ? <PriceSlash price={billPriceUnlimited} discountedPrice={slashedPrice.price} discountMultiplier={discountMultiplier ?? 1} dailyPrice={billPriceUnlimitedDaily} displayDaily={displayDaily} slashedPrice={slashedPrice}/> :
                                billPriceUnlimitedDiscounted ? (
                                    <div>
                                        <div className="discount-banner">
                                            <p className="discount-banner-text">
                                                {Math.ceil(
                                                    (1 - discountMultiplier) *
                                                        100
                                                )}
                                                % OFF
                                            </p>
                                        </div>
                                        <h1
                                            className="price-unlimited"
                                            style={{
                                                textDecoration: "line-through",
                                                fontSize: 28,
                                            }}
                                        >
                                            £{billPriceUnlimited}
                                        </h1>
                                        <h1 className="price-unlimited">
                                            £{billPriceUnlimitedDiscounted}
                                        </h1>
                                    </div>
                                ) : (
                                    <h1 className="price-unlimited">
                                        £
                                        {displayDaily
                                            ? billPriceUnlimitedDaily
                                            : billPriceUnlimited}
                                    </h1>
                                )}
                                <p>
                                    per tenant a {displayDaily ? "day" : "week"}
                                </p>
                                <p className="content-cover-text">
                                    Plus £100 worth of FREE Content Cover and Wellbeing Services
                                </p>
                                <Select
                                    defaultValue="true"
                                    onChange={handleFrequencyChange}
                                    variant="plain"
                                >
                                    <Option value="true">Daily</Option>
                                    <Option value="false">Weekly</Option>
                                </Select>
                            </div>
                            <div className="container benefits-section">
                                <div className="top-row">
                                    <div className="row align-items-start utility-row">
                                        <div
                                            style={{
                                                color: "black",
                                                fontSize: "18px",
                                            }}
                                            className="utility"
                                        >
                                            <img
                                                src={gas}
                                                alt="gas"
                                                className="py-1 icon-height"
                                            />
                                            <p className="utility-font">
                                                Unlimited Gas
                                            </p>
                                        </div>
                                    </div>

                                    {!isScottishCity && (
                                        <div className="row align-items-start utility-row">
                                            <div
                                                style={{
                                                    color: "black",
                                                    fontSize: "18px",
                                                }}
                                                className="utility"
                                            >
                                                <img
                                                    src={water}
                                                    alt="water"
                                                    className="py-1 icon-height"
                                                />
                                                <p className="utility-font">
                                                    Unlimited Water
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="top-row">
                                    <div className="row align-items-start utility-row">
                                        <div
                                            style={{
                                                color: "black",
                                                fontSize: "18px",
                                            }}
                                            className="utility"
                                        >
                                            <img
                                                src={lightning}
                                                alt="lightning"
                                                className="py-1 icon-height"
                                            />
                                            <p className="utility-font">
                                                Unlimited Energy
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row align-items-start utility-row">
                                        <div
                                            style={{
                                                color: "black",
                                                fontSize: "18px",
                                            }}
                                            className="utility"
                                        >
                                            <img
                                                src={wifi}
                                                alt="wifi"
                                                className="py-1 icon-height"
                                            />
                                            <p className="utility-font">
                                                Internet (400 mbps)
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row align-items-start utility-row">
                                        <div
                                            style={{
                                                color: "black",
                                                fontSize: "18px",
                                            }}
                                            className="utility"
                                        >
                                            <img
                                                src={contents}
                                                alt="contents"
                                                className="py-1 icon-height"
                                            />
                                            <p className="utility-font">
                                                FREE Contents Insurance
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="continue">
                        <div style={{ width: "100%" }}>
                            <Link
                                to={url}
                                state={{
                                    bedrooms: bedrooms,
                                    leadTenantId: leadTenantId,
                                    leadTenantNumber: leadTenantNumber,
                                    leadTenantName: leadTenantName,
                                    leadTenantEmail: leadTenantEmail,
                                    package: "unlimited",
                                    discountedPrice: slashedPrice.price ?? billPriceUnlimitedDiscounted,
                                    discountCode: discount,
                                    flatmateData,
                                    hubSpotContactIds,
                                }}
                            >
                                <Button className="fw-bold w-100 get-quote-button">
                                    Continue
                                </Button>
                            </Link>
                        </div>
                        <Link
                            to={"/"}
                            className="link back-link"
                            state={{
                                bedrooms: bedrooms,
                                leadTenantId: leadTenantId,
                                leadTenantNumber: leadTenantNumber,
                                leadTenantName: leadTenantName,
                                leadTenantEmail: leadTenantEmail,
                                package: "unlimited",
                                discountedPrice: slashedPrice.price ?? billPriceUnlimitedDiscounted,
                                discountCode: discount,
                                flatmateData,
                                hubSpotContactIds,
                            }}
                        >
                            Back
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
