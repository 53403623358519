import { useEffect } from "react"

export const useBeforeUnload = (modalToPopUp) => {
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const message = "Leaving so soon?";
            event.returnValue = message;
            return message;
          };

          if(modalToPopUp){
            window.addEventListener('beforeunload', handleBeforeUnload)
          } else {
            window.removeEventListener('beforeunload', handleBeforeUnload)
          }

          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
          }
    }, [modalToPopUp])
}