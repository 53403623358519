import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Form,
    FormCheck,
    InputGroup,
    Modal,
} from "react-bootstrap";
import ConfettiExplosion from "react-confetti-explosion";
import { InnerInput } from "../Components/ui/FloatingLabel";
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
} from "react-google-places-autocomplete";
import PhoneInput from "react-phone-number-input";
import { useLocation, useSearchParams } from "react-router-dom";
import edit_icon from "../Icons/edit.png";
import housr_logo from "../Icons/housr_icon.svg";
import { useBeforeUnload } from "../utils";
import Input from "@mui/joy/Input";
import { FormControl, FormLabel } from "@mui/joy";
import "./css/Tenants.css";
import { TermsAndConditions } from "./TermsAndConditions";

export const Tenants = () => {
    let { state } = useLocation();

    const [address, setAddress] = useState({
        firstAddress: "",
        secondAddress: "",
        city: "",
        postcode: "",
    });
    const [tenancyStart, setTenancyStart] = useState(
        new Date().getFullYear() + "-07-01"
    );
    const [tenancyEnd, setTenancyEnd] = useState("");
    const [changeTenancyEnd, setChangeTenancyEnd] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertHeading, setAlertHeading] = useState("");
    const [alertBody, setAlertBody] = useState("");
    const [referralCode, setReferralCode] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [searchAddress, setSearchAddress] = useState(null);
    const [currentModalIndex, setCurrentModalIndex] = useState(-1);
    const [showModal, setShowModal] = useState(false);
    const [displayedFlatmateData, setDisplayedFlatmateData] = useState([]);
    const [isExploding, setIsExploding] = useState(false);
    const [showTC, setShowTC] = useState(false);

    const [searchParams] = useSearchParams();
    const referral = searchParams.get("referral");

    const tenants = parseInt(state?.bedrooms);
    const leadTenant = {
        leadTenantId: state?.leadTenantId,
        leadTenantNumber: state?.leadTenantNumber,
        leadTenantName: state?.leadTenantName,
        leadTenantEmail: state?.leadTenantEmail,
    };
    const discountedPrice = state?.discountedPrice;
    const discountCode = state?.discountCode;
    const leadTenantFlatmateData = state?.flatmateData;
    const hubSpotContactIds = state?.hubSpotContactIds;

    useEffect(() => {
        if (leadTenantFlatmateData?.length > 0) {
            for (let i = 0; i < leadTenantFlatmateData.length; i++) {
                setDisplayedFlatmateData((currentFlatmateData) => {
                    const newData = [...currentFlatmateData];
                    newData[i] = leadTenantFlatmateData[i];
                    return newData;
                });
            }
        }

        setAddress({});

        if (referral) {
            setReferralCode(referral);
        }

        if (tenancyEnd === "") {
            addOneYear(tenancyStart);
        }

        setAddress((prevAddress) => {
            let updatedAddress = {};

            if (searchAddress?.value?.place_id) {
                geocodeByPlaceId(searchAddress?.value?.place_id)
                    .then((results) => {
                        const addressComponents = results[0].address_components;
                        setAddress((prevAddress) => ({
                            ...prevAddress,
                            postcode:
                                addressComponents[addressComponents.length - 1]
                                    .long_name,
                        }));
                    })
                    .catch((error) => console.error(error));
            }

            const formattedSearchAddress = searchAddress?.label.split(",");
            if (formattedSearchAddress?.length > 0) {
                updatedAddress = {
                    ...prevAddress,
                    firstAddress: formattedSearchAddress[0],
                };

                if (formattedSearchAddress?.length > 2) {
                    updatedAddress.secondAddress =
                        formattedSearchAddress[1].trimStart();
                }

                updatedAddress.city =
                    formattedSearchAddress[
                        formattedSearchAddress.length - 1
                    ].trimStart();
            }

            return updatedAddress;
        });
    }, [
        referral,
        searchAddress,
        tenancyStart,
        tenancyEnd,
        submitted,
        leadTenantFlatmateData,
    ]);

    useBeforeUnload(!submitted);

    const numOfTenants = [];

    if (tenants > 1) {
        for (let i = 0; i < tenants; i++) {
            numOfTenants.push(i);
        }
    }

    const submitForm = () => {
        setShowAlert(false);
        window.scroll({ top: 0, behavior: "smooth" });
        if (!address.firstAddress) {
            setAlertHeading("Error with your address");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your address has not been completely filled in yet!"
            );
            setShowAlert(true);
        } else if (!address.city) {
            setAlertHeading("Error with your city");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your city has not been chosen!"
            );
            setShowAlert(true);
        } else if (!address.postcode) {
            setAlertHeading("Error with your postcode");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your postcode has not been completely filled in yet!"
            );
            setShowAlert(true);
        } else if (!tenancyStart) {
            setAlertHeading("Error with your tenancy start date");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your tenancy start date has not been filled in yet!"
            );
            setShowAlert(true);
        } else if (changeTenancyEnd && !tenancyEnd) {
            setAlertHeading("Error with your tenancy end date");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your tenancy end date has not been filled in yet!"
            );
            setShowAlert(true);
        } else if (
            displayedFlatmateData.length < tenants - 1 ||
            displayedFlatmateData.some(
                (flatmate) =>
                    !flatmate.firstName ||
                    !flatmate.lastName ||
                    !flatmate.number
            )
        ) {
            setAlertHeading("Error with your tenant details");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected one or more of your tenant details has not been filled in yet!"
            );
            setShowAlert(true);
        } else if (
            displayedFlatmateData.some(
                (flatmate) => !isPhoneNumberValid(flatmate.number)
            )
        ) {
            setAlertHeading("Invalid Phone Number");
            setAlertBody(
                "One or more of the phone numbers you have entered is invalid. Please ensure you have entered the correct phone number for all of your flatmates."
            );
            setShowAlert(true);
        } else {
            postRequest();
        }
    };

    const isPhoneNumberValid = (number) => {
        return !number.startsWith("+44") || number.length === 13;
    };

    const postRequest = () => {
        setSubmitting(true);
        axios
            .post("/house_tenants", {
                address,
                bedrooms: tenants,
                tenancyStart,
                tenancyEnd,
                displayedFlatmateData,
                referralCode,
                leadTenant,
                package: state.package,
                discountedPrice,
                discountCode,
                leadTenantFlatmates: leadTenantFlatmateData,
                hubSpotContactIds,
            })
            .then(() => {
                setIsExploding(true);
                resetForm();
            })
            .catch((err) => {
                console.error(err);
                setSubmitting(false);
                setSubmitted(false);
                if (err.response?.data) {
                    setAlertHeading("Error with your request");
                    setAlertBody(err.response?.data);
                } else {
                    setAlertHeading("Error with your request");
                    setAlertBody("Something went wrong, please try again");
                }
                setShowAlert(true);
            });
    };

    const addOneYear = (startDate) => {
        let date = new Date(startDate);

        date.setFullYear(date.getFullYear() + 1);
        date.setDate(date.getDate() - 1);

        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, "0");
        let day = String(date.getDate()).padStart(2, "0");

        let endDate = `${year}-${month}-${day}`;
        setTenancyEnd(endDate);
    };

    const resetForm = () => {
        setSubmitting(false);
        setSubmitted(true);
        setAddress({});
        setTenancyStart("");
        setTenancyEnd("");
        setReferralCode("");
        setSearchAddress(null);
        setChangeTenancyEnd(false);
    };

    const AlertValidation = () => {
        return (
            <Alert
                show={showAlert}
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
                style={{
                    zIndex: 10,
                    width: "100%",
                }}
            >
                <Alert.Heading>{alertHeading}</Alert.Heading>
                <p>{alertBody}</p>
            </Alert>
        );
    };

    const FormSubmitted = () => {
        return (
            <div className="bg-success text-light p-3 mt-2 success-popup">
                <h5>Form Submitted</h5>
                <p>
                    You should receive a SMS message with a link to the
                    utilities form for you to sign.
                </p>
            </div>
        );
    };

    const handleModalStart = (num) => {
        setCurrentModalIndex(num - 1);
        setShowModal(true);
    };

    const handleModalClose = () => {
        if (currentModalIndex < tenants) {
            setCurrentModalIndex(currentModalIndex + 1);
        }
    };

    const handleFlatmateChange = (e, type) => {
        let value;
        if (type === "number") {
            value = e;
        } else {
            value = e.target.value;
        }
        setDisplayedFlatmateData((flatmateData) => {
            const newData = [...flatmateData];
            newData[currentModalIndex] = {
                ...newData[currentModalIndex],
                [type]: value,
            };
            return newData;
        });
    };

    const handleModalFinalClose = () => {
        setShowModal(false);
        setCurrentModalIndex(tenants + 1);
    };

    const TermsConditionsModal = () => {
        return (
            <Modal
                show={showTC}
                onHide={() => {
                    setShowTC(false);
                }}
                className="d-flex flex-column"
            >
                <Modal.Header closeButton className="fw-bold">
                    Terms & Conditions
                </Modal.Header>
                <Modal.Body>
                    <TermsAndConditions />
                </Modal.Body>
            </Modal>
        );
    };

    return tenants ? (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                minHeight: "100vh",
                backgroundColor: "#f8f9fa",
            }}
        >
            <div
                className="bg-white p-3 card-width"
                style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                }}
            >
                <TermsConditionsModal />
                <Form className="tenant-details-signup-form mx-auto">
                    <div className="housr-logo">
                        <img
                            src={housr_logo}
                            style={{ height: "4vh" }}
                            alt="logo"
                        />
                    </div>
                    <h1 className="signup-header text-center ps-0">
                        Let's get you on board!
                    </h1>
                    <AlertValidation />
                    {submitted && <FormSubmitted />}
                    <div>
                        <FormLabel className="fw-bold pb-1">
                            Your Address
                        </FormLabel>
                        <InputGroup
                            style={{
                                backgroundColor: "#fbfcfe",
                                boxShadow:
                                    "var(--joy-shadowRing, 0 0 #000), 0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))",
                                borderColor: "rgb(205, 215, 225)",
                                borderWidth: "1px",
                                borderRadius: "6px",
                            }}
                        >
                            <div style={{ width: "100%" }}>
                                <GooglePlacesAutocomplete
                                    apiOptions={{
                                        language: "en",
                                        region: "uk",
                                    }}
                                    apiKey={
                                        "AIzaSyAx9PXk8lKpp3o19zYBOiNX1UB5YroEBLQ"
                                    }
                                    selectProps={{
                                        placeholder:
                                            "Start typing your full address",
                                        value: searchAddress,
                                        onChange: setSearchAddress,
                                    }}
                                    style={{
                                        backgroundColor: "#fbfcfe",
                                        boxShadow:
                                            "var(--joy-shadowRing, 0 0 #000), 0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))",
                                        borderColor: "rgb(205, 215, 225)",
                                        borderWidth: "1px",
                                        borderRadius: "6px",
                                    }}
                                />
                            </div>
                        </InputGroup>
                        <Form.Group className="mt-3">
                            <FormControl id="firstAddress">
                                <Input
                                    type="address"
                                    name="firstAddress"
                                    value={address.firstAddress}
                                    onChange={(e) => {
                                        setAddress({
                                            ...address,
                                            firstAddress: e.target.value,
                                        });
                                    }}
                                    slots={{ input: InnerInput }}
                                    slotProps={{
                                        input: {
                                            placeholder: "",
                                            type: "address",
                                            label: "Address Line One",
                                        },
                                    }}
                                    sx={{
                                        "--Input-radius": "6px",
                                    }}
                                />
                            </FormControl>
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <FormControl id="secondAddress">
                                <Input
                                    type="address"
                                    name="secondAddress"
                                    value={address.secondAddress}
                                    onChange={(e) => {
                                        setAddress({
                                            ...address,
                                            secondAddress: e.target.value,
                                        });
                                    }}
                                    slots={{ input: InnerInput }}
                                    slotProps={{
                                        input: {
                                            placeholder: "",
                                            type: "address",
                                            label: "Address Line Two",
                                        },
                                    }}
                                    sx={{
                                        "--Input-radius": "6px",
                                    }}
                                />
                            </FormControl>
                        </Form.Group>
                        <Form.Group className="d-flex w-100 justify-content-between mt-2">
                            <FormControl id="city" style={{ width: "49%" }}>
                                <Input
                                    type="text"
                                    name="city"
                                    value={address.city}
                                    onChange={(e) => {
                                        setAddress({
                                            ...address,
                                            city: e.target.value,
                                        });
                                    }}
                                    slots={{ input: InnerInput }}
                                    slotProps={{
                                        input: {
                                            placeholder: "",
                                            type: "text",
                                            label: "City",
                                        },
                                    }}
                                    sx={{
                                        "--Input-radius": "6px",
                                    }}
                                />
                            </FormControl>
                            <FormControl id="postcode" style={{ width: "49%" }}>
                                <Input
                                    type="text"
                                    name="postcode"
                                    value={address.postcode}
                                    placeholder="Postcode"
                                    onChange={(e) => {
                                        setAddress({
                                            ...address,
                                            postcode: e.target.value,
                                        });
                                    }}
                                    slots={{ input: InnerInput }}
                                    slotProps={{
                                        input: {
                                            placeholder: "",
                                            type: "text",
                                            label: "Postcode",
                                        },
                                    }}
                                    sx={{
                                        "--Input-radius": "6px",
                                    }}
                                />
                            </FormControl>
                        </Form.Group>
                        <div className="d-flex pt-2 justify-content-between ">
                            <FormLabel className="fw-bold py-2">
                                Tenancy Start Date
                            </FormLabel>
                            {changeTenancyEnd && (
                                <FormLabel className="justify-content-start d-flex w-50 fw-bold py-2">
                                    Tenancy End Date
                                </FormLabel>
                            )}
                        </div>
                        <Form.Group className="d-flex justify-content-between">
                            <FormControl
                                id="tenancyStartDate"
                                style={{ width: "49%" }}
                            >
                                <Input
                                    type="date"
                                    min={new Date().toISOString().split("T")[0]}
                                    name="tenancyStartDate"
                                    value={tenancyStart}
                                    onChange={(e) => {
                                        setTenancyStart(e.target.value);
                                        addOneYear(e.target.value);
                                    }}
                                />
                            </FormControl>
                            {changeTenancyEnd && (
                                <FormControl
                                    id="tenancyStartDate"
                                    style={{ width: "49%" }}
                                >
                                    <Input
                                        type="date"
                                        name="tenancyEndDate"
                                        value={tenancyEnd}
                                        min={tenancyStart}
                                        onChange={(e) => {
                                            setTenancyEnd(e.target.value);
                                        }}
                                    />
                                </FormControl>
                            )}
                        </Form.Group>
                        <Form.Group
                            className="d-flex flex-row mt-2"
                            controlId="exampleForm.ControlInput1"
                        >
                            <FormCheck
                                className="me-3"
                                type={"checkbox"}
                                checked={changeTenancyEnd}
                                onChange={() => {
                                    setTenancyEnd("");
                                    setChangeTenancyEnd(!changeTenancyEnd);
                                }}
                            />
                            <Form.Label
                                className="text-start"
                                style={{ fontSize: "14px" }}
                            >
                                Click here if you don't have a 12-month
                                contract.
                            </Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <FormControl>
                                <FormLabel className="fw-bold">
                                    Referral Code
                                </FormLabel>
                                <Input
                                    value={referralCode}
                                    onChange={(e) => {
                                        setReferralCode(
                                            e.target.value.toUpperCase()
                                        );
                                    }}
                                />
                            </FormControl>
                        </Form.Group>
                    </div>

                    {tenants > 1 && (
                        <div className="mt-2">
                            <FormLabel className="py-3 fw-bold">
                                Tenants Information
                            </FormLabel>
                            <div className="mb-3 housemates-section">
                                {numOfTenants.map((numOfTenant, index) => {
                                    return (
                                        <div className="mb-1" key={numOfTenant}>
                                            {index === 0 ? (
                                                <div className="d-flex flex-column">
                                                    <div className="d-flex justify-content-between">
                                                        <h6 className="d-flex justify-content-start ps-1">
                                                            {
                                                                leadTenant.leadTenantName
                                                            }{" "}
                                                            (you)
                                                        </h6>
                                                        <h6 className="d-flex justify-content-start">
                                                            {
                                                                leadTenant.leadTenantNumber
                                                            }
                                                        </h6>
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderBottom:
                                                                "1px solid black",
                                                            margin: "5px 0",
                                                            width: "100%",
                                                            borderColor: "grey",
                                                            opacity: 0.5,
                                                        }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <div className="d-flex flex-column">
                                                    <div className="d-flex justify-content-between">
                                                        <h6 className="d-flex justify-content-start ps-1">
                                                            {displayedFlatmateData[
                                                                numOfTenant - 1
                                                            ]?.firstName
                                                                ? displayedFlatmateData[
                                                                      numOfTenant -
                                                                          1
                                                                  ]?.firstName +
                                                                  " " +
                                                                  (displayedFlatmateData[
                                                                      numOfTenant -
                                                                          1
                                                                  ]?.lastName ||
                                                                      "")
                                                                : "Tenant " +
                                                                  numOfTenant}
                                                        </h6>
                                                        {displayedFlatmateData[
                                                            numOfTenant - 1
                                                        ]?.number ? (
                                                            <h6 className="d-flex align-items-center">
                                                                {
                                                                    displayedFlatmateData[
                                                                        numOfTenant -
                                                                            1
                                                                    ]?.number
                                                                }
                                                                <button
                                                                    className="ms-1"
                                                                    onClick={() =>
                                                                        handleModalStart(
                                                                            numOfTenant
                                                                        )
                                                                    }
                                                                    style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            edit_icon
                                                                        }
                                                                        alt="edit"
                                                                    />
                                                                </button>
                                                            </h6>
                                                        ) : (
                                                            <a
                                                                onClick={() =>
                                                                    handleModalStart(
                                                                        numOfTenant
                                                                    )
                                                                }
                                                                className="d-flex justify-content-start text-success"
                                                                style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                Add
                                                            </a>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderBottom:
                                                                "1px solid black",
                                                            margin: "5px 0",
                                                            width: "100%",
                                                            borderColor: "grey",
                                                            opacity: 0.5,
                                                        }}
                                                    ></div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <Form.Group className="pb-lg-5 pb-3">
                        <Button
                            onClick={() => {
                                submitForm();
                            }}
                            disabled={submitting}
                            hidden={submitted}
                            className="housr-button w-100"
                            style={{ top: "1vh", position: "relative" }}
                        >
                            Sign Up
                        </Button>
                        <div className="mt-3 w-75 mx-auto">
                            <span
                                className="text-start"
                                style={{ fontSize: "12px" }}
                            >
                                By signing up, you agree to the{" "}
                                <a
                                    style={{
                                        color: "#109539",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setShowTC(true);
                                    }}
                                >
                                    Terms & Conditions.
                                </a>
                            </span>
                        </div>
                        <Button
                            onClick={() => {
                                window.location.href =
                                    "https://www.housrapp.co.uk";
                            }}
                            hidden={!submitted}
                            className="housr-button w-100"
                            style={{ backgroundColor: "blue" }}
                        >
                            Go Home
                        </Button>
                    </Form.Group>
                </Form>
                <Modal
                    animation={false}
                    show={showModal & (currentModalIndex < tenants)}
                    onHide={handleModalFinalClose}
                    centered
                    style={{ borderRadius: "20px" }}
                >
                    <Modal.Header className="justify-content-center">
                        <Modal.Title c style={{ justifyContent: "center" }}>
                            Add Tenant {currentModalIndex + 1}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-card my-2 mb-4">
                        <FormControl>
                            <FormLabel>First Name</FormLabel>
                            <Input
                                value={
                                    displayedFlatmateData[currentModalIndex]
                                        ?.firstName ?? ""
                                }
                                onChange={(e) => {
                                    handleFlatmateChange(e, "firstName");
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Last Name</FormLabel>
                            <Input
                                value={
                                    displayedFlatmateData[currentModalIndex]
                                        ?.lastName ?? ""
                                }
                                onChange={(e) => {
                                    handleFlatmateChange(e, "lastName");
                                }}
                            />
                        </FormControl>
                        <Form.Group>
                            <FormLabel>Phone Number</FormLabel>
                            <PhoneInput
                                placeholder="Enter phone number"
                                withCountryCallingCode
                                defaultCountry={"GB"}
                                value={
                                    displayedFlatmateData[currentModalIndex]
                                        ?.number
                                }
                                onChange={(e) => {
                                    handleFlatmateChange(e, "number");
                                }}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center d-flex">
                        {currentModalIndex === tenants - 2 ? (
                            <Button
                                className="housr-button m-2"
                                style={{ width: "90%" }}
                                onClick={handleModalFinalClose}
                            >
                                Finish
                            </Button>
                        ) : (
                            <Button
                                className="housr-button m-2"
                                style={{ width: "90%" }}
                                onClick={handleModalClose}
                            >
                                Next
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
                {isExploding && (
                    <ConfettiExplosion
                        style={{
                            position: "absolute",
                            left: "50%",
                            top: "30%",
                        }}
                        zIndex={99}
                        force={0.8}
                        duration={4000}
                        particleCount={250}
                        width={1600}
                    />
                )}
            </div>
        </div>
    ) : (
        <div className="mt-5">
            <h4>
                Error 404: Something went wrong - please start the form again.
            </h4>
        </div>
    );
};
