import { useEffect, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import contents from "../Icons/Contents.svg";
import lightning from "../Icons/Electric.svg";
import gas from "../Icons/gas.svg";
import water from "../Icons/water.svg";
import wifi from "../Icons/Wifi.svg";
import { getBillPrice, getQuoteRetrieval } from "../api";
import "./css/QuoteDisplay.css";

import { Option, Select } from "@mui/joy";
import { checkScottishCity } from "../lib/utils";

export const QuoteRetrieval = () => {
    const [searchParams] = useSearchParams();
    const uuid = searchParams.get("uuid");

    const [billPriceUnlimited, setBillPriceUnlimited] = useState("");
    const [billPriceUnlimitedDaily, setBillPriceUnlimitedDaily] = useState("");
    const [billPriceUnlimitedDiscounted, setBillPriceUnlimitedDiscounted] =
        useState(null);
    const [billPriceLite, setBillPriceLite] = useState("");
    const [user, setUser] = useState({});
    const [displayDaily, setDisplayDaily] = useState(true);
    const div1Ref = useRef(null);
    const isScottishCity = checkScottishCity(user.city);

    function handleFrequencyChange(e, newValue) {
        let bool = newValue === "false";
        setDisplayDaily(!bool);
    }

    useEffect(() => {
        if (uuid) {
            getQuoteRetrieval(uuid).then((response) => {
                setUser(response);
                const isScottishCity = checkScottishCity(response.city);
                getBillPrice(response.bedrooms, isScottishCity).then((res) => {
                    if (response.discount_code) {
                        setBillPriceUnlimitedDiscounted(
                            (
                                res.price?.[0][0]?.price *
                                response.discountMultiplier
                            ).toFixed(2)
                        );
                    }
                    setBillPriceUnlimited(
                        parseFloat(res.price?.[0][0]?.price).toFixed(2)
                    );
                    setBillPriceUnlimitedDaily(
                        parseFloat(res.price?.[0][0]?.price / 7).toFixed(2)
                    );
                    setBillPriceLite(
                        parseFloat(res.price?.[1][0]?.price).toFixed(2)
                    );
                });
            });
        }
    }, [uuid, user.bedrooms, billPriceUnlimited, billPriceLite]);

    const referral = searchParams.get("referral");
    let url = `/signup/tenants`;

    if (referral) {
        url += `?referral=${referral}`;
    }

    return billPriceLite === "" ||
        isNaN(billPriceLite) ||
        isNaN(billPriceUnlimited) ||
        isNaN(billPriceUnlimitedDiscounted) ? (
        <div
            className="d-flex justify-content-center flex-column align-items-center w-100 border"
            style={{ height: "100vh" }}
        >
            <h3>Fetching your bill quote...</h3>
            <Spinner />
        </div>
    ) : (
        <div style={{ backgroundColor: "#f8f9fa" }}>
            <div
                className="d-flex justify-content-center"
                style={{ height: "100vh" }}
            >
                <div className="d-flex flex-column justify-content-evenly py-3">
                    <div
                        style={{
                            height: "15vh",
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                        }}
                    >
                        <h1 className="fw-bold">
                            Welcome back, {user.name.split(" ")?.[0]} 👋
                        </h1>
                        <h3 className="fw-bold">Your Personal Quote</h3>
                        <span className="justify-content-center">
                            For {user.bedrooms}{" "}
                            {user.bedrooms > 1 ? "housemates" : "housemate"}
                        </span>
                    </div>
                    <div
                        className="quote-card-selection"
                        ref={div1Ref}
                        tabIndex="0"
                    >
                        <div>
                            <h3 className="package-name">Housr Unlimited</h3>
                        </div>
                        <div className="border-bottom pricing-container">
                            {billPriceUnlimitedDiscounted ? (
                                <div>
                                    <div className="discount-banner">
                                        <p className="discount-banner-text">
                                            {Math.ceil(
                                                (1 - user?.discountMultiplier) *
                                                    100
                                            )}
                                            % OFF
                                        </p>
                                    </div>
                                    <h1
                                        className="price-unlimited"
                                        style={{
                                            textDecoration: "line-through",
                                            fontSize: 28,
                                        }}
                                    >
                                        £{billPriceUnlimited}
                                    </h1>
                                    <h1 className="price-unlimited">
                                        £{billPriceUnlimitedDiscounted}
                                    </h1>
                                </div>
                            ) : (
                                <h1 className="price-unlimited">
                                    £
                                    {displayDaily
                                        ? billPriceUnlimitedDaily
                                        : billPriceUnlimited}
                                </h1>
                            )}
                            <p>per tenant a {displayDaily ? "day" : "week"}</p>
                            <Select
                                defaultValue="true"
                                onChange={handleFrequencyChange}
                                variant="plain"
                            >
                                <Option value="true">Daily</Option>
                                <Option value="false">Weekly</Option>
                            </Select>
                        </div>

                        <div className="container benefits-section">
                            <div className="top-row">
                                <div className="row align-items-start utility-row">
                                    <div
                                        style={{
                                            color: "black",
                                            fontSize: "18px",
                                        }}
                                        className="utility"
                                    >
                                        <img
                                            src={gas}
                                            alt="gas"
                                            className="py-1 icon-height"
                                        />
                                        <p className="utility-font">
                                            Unlimited Gas
                                        </p>
                                    </div>
                                </div>

                                {!isScottishCity && (
                                    <div className="row align-items-start utility-row">
                                        <div
                                            style={{
                                                color: "black",
                                                fontSize: "18px",
                                            }}
                                            className="utility"
                                        >
                                            <img
                                                src={water}
                                                alt="water"
                                                className="py-1 icon-height"
                                            />
                                            <p className="utility-font">
                                                Unlimited Water
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="top-row">
                                <div className="row align-items-start utility-row">
                                    <div
                                        style={{
                                            color: "black",
                                            fontSize: "18px",
                                        }}
                                        className="utility"
                                    >
                                        <img
                                            src={lightning}
                                            alt="lightning"
                                            className="py-1 icon-height"
                                        />
                                        <p className="utility-font">
                                            Unlimited Energy
                                        </p>
                                    </div>
                                </div>
                                <div className="row align-items-start utility-row">
                                    <div
                                        style={{
                                            color: "black",
                                            fontSize: "18px",
                                        }}
                                        className="utility"
                                    >
                                        <img
                                            src={wifi}
                                            alt="wifi"
                                            className="py-1 icon-height"
                                        />
                                        <p className="utility-font">
                                            Internet (400 mbps)
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            color: "black",
                                            fontSize: "18px",
                                        }}
                                        className="utility"
                                    >
                                        <img
                                            src={contents}
                                            alt="contents"
                                            className="py-1 icon-height"
                                        />
                                        <p className="utility-font">
                                            FREE Contents Insurance
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="continue">
                        <div style={{ width: "100%" }}>
                            <Link
                                to={url}
                                state={{
                                    bedrooms: user.bedrooms,
                                    leadTenantId: user.id,
                                    leadTenantNumber: user.contact_no,
                                    leadTenantName: user.name,
                                    package: "unlimited",
                                    discountedPrice:
                                        billPriceUnlimitedDiscounted,
                                    discountCode: user.discount_code,
                                }}
                            >
                                <Button className="fw-bold w-100 get-quote-button-retrieval">
                                    Continue
                                </Button>
                            </Link>
                        </div>
                        <Link
                            to={"/"}
                            className="link back-link"
                            state={{
                                user: user,
                            }}
                        >
                            Back
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
