import React from "react";

export const PriceSlash = ({ price, discountedPrice, discountMultiplier, dailyPrice, displayDaily, slashedPrice}) => {

    return (
        <div>
            <h1 className="price-unlimited price-slashed">£{displayDaily ? dailyPrice :  price}</h1>
            <h1 className="price-unlimited">£{displayDaily ? (slashedPrice.dailyPrice * discountMultiplier).toFixed(2) : discountedPrice}</h1>
        </div>
    );
};
