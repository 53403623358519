import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { shareOnMobile } from "react-mobile-share";
import { useLocation, useNavigate } from "react-router-dom";
import { Coins } from "../Icons/coins.js";
import earnings from "../Icons/earnings.png";
import forms from "../Icons/forms.png";
import pending from "../Icons/pending.png";
import referrals from "../Icons/referrals.png";
import { getLeaderboard } from "../api";
import { PageCard } from "./PageCard";
import "./css/Dashboard.css";

export const Dashboard = () => {

  const location = useLocation();

  const [balance, setBalance] = useState(0);
  const [signups, setSignups] = useState(0);
  const [leaderboard, setLeaderboard] = useState([{}]);
  const [awaitSignups, setAwaitSignups] = useState(0);
  const [referralCode, setReferralCode] = useState("");;
  const [loaded, setLoaded] = useState(false);
  const [name, setName] = useState();
  const [copied, setCopied] = useState(false)
  const [myPosition, setMyPosition] = useState()
  const [fullLeaderboard, setFullLeaderboard] = useState([{}])
  const [referralAmount, setReferralAmount] = useState();
  const [showModal, setShowModal] = useState(location.state?.signup ?? false);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get("/dashboardData", { withCredentials: true }).then((res) => {
      setBalance(res.data.balance);
      setSignups(res.data.signups);
      setAwaitSignups(res.data.awaiting_signups);
      setReferralCode(res.data.ref_code);
      setReferralAmount(res.data.referralAmount);
      setLoaded(true);
      setName(res.data.name)
    }).catch(error => {
      if (error.response?.status === 401) {
        navigate('/login')
      };
    });

    getLeaderboard()
      .then((res) => {
        setFullLeaderboard(res.leaderboard)
        findNearbyLeaderboard(res.leaderboard, res.userDetails.id)
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate('/login')
        };
      })
  },  [navigate]);

  const findNearbyLeaderboard = (list, id) => {
    const index = list.findIndex((person) => person.id === id);

    setMyPosition(index)

    if (index <= 2) {
      setLeaderboard(list.slice(0, 5));
    } else if (index >= list.length - 3) {
      setLeaderboard(list.slice(-5));
    } else {
      setLeaderboard(list.slice(index - 2, index + 3));
    }
  };

  const ReferralTile = () => {
    return (
      <div className="share-container">
            <div className="share section d-flex flex-column align-items-center">
              <div className="share-title">Hello {name}!</div>
              <div className="share-detail">Copy your code below to start earning today.</div>
              <div className="share-detail">Every tenant that signs up earns you <b>£{referralAmount}!</b></div>
              <div className="text-start w-100 mt-4"><b>Code</b></div>
              <div className="d-flex w-100">
                <div className="referral-code d-flex flex-column">
                  <div className="m-auto">
                    {referralCode}
                  </div>
                </div>
                <div className="copy-button" onClick={() => shareLink(referralCode, setCopied)}>
                  <div>
                    {copied ? "Copied!" : "Share"}
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
  }

  return loaded ? (
    <PageCard withSidebar cardColour="#F5F7FA">
      <Modal show={showModal} className="share-modal" onHide={() => {setShowModal(false)}} >
              <div className="share-title">Hello {name}!</div>
              <div className="share-detail">Copy your code below to start earning today.</div>
              <div className="share-detail">Every tenant that signs up earns you <b>£{referralAmount}!</b></div>
              <div className="text-start w-100 mt-4"><b>Code</b></div>
              <div className="d-flex w-100">
                <div className="referral-code d-flex flex-column">
                  <div className="m-auto">
                    {referralCode}
                  </div>
                </div>
                <div className="copy-button" onClick={() => {
                  shareLink(referralCode, setCopied);
                  setTimeout(() => {
                    setShowModal(false)
                  }, 1000)}}>
                  <div>
                    {copied ? "Copied!" : "Share"}
                  </div>
                </div>
              </div>
      </Modal>
      <div className="h-100 w-100 inner-card">
        <div className="dashboard-column">
          <div className="title">Your dashboard</div>
          <div className="info-section">
            <InfoSection icon={referrals} label={"Referrals"} value={signups} valueColour={"#109539"} />
            <InfoSection icon={earnings} label={"Earnings"} value={`£${balance.toFixed(2)}`} valueColour={"#109539"} />
            <InfoSection icon={pending} label={"Pending"} value={`£${(awaitSignups * referralAmount).toFixed(2)}`} valueColour="#10958D" />
            <InfoSection icon={forms} label={"Forms to Sign"} value={awaitSignups} valueColour="#10958D" />
          </div>
          <div className="d-xxl-none">
          <ReferralTile/>
          </div>
          <div className="tips-container">
            <div className="tips section d-flex flex-column align-items-center px-3">
              <div className="tips-title">Need some support?</div>
              <div className="tips-detail">Here are some of our best tips! Shush don't tell anyone.</div>
              <div className="tip"><b>1. Share your code on Instagram: </b>Expand your reach by sharing your code.</div>
              <div className="tip"><b>2. Give your friends a nudge: </b>Don't let them stand between you and withdrawing your pot of gold!</div>
              <div className="tip"><b>3. Tell your friends you'll split the money: </b>AKA tell them you're getting less money than you are, and buy them a pint.</div>
            </div>
          </div>
        </div>
        <div className="dashboard-column">
          <div className="d-xxl-block d-none">
          <ReferralTile/>
          </div>
          <div className="leaderboard-outer-container">
            <div className="leaderboard section d-flex flex-column align-items-start">
              <div className="leaderboard-title">Leaderboard</div>
              <div className="inner-leaderboard h-100 w-100">
                {leaderboard.map((row, index) => {
                  return <LeaderboardRow row={row}
                    myPosition={myPosition + 1}
                    position={myPosition <= 2
                      ? index + 1
                      : myPosition >= fullLeaderboard.length - 2
                        ? fullLeaderboard.length - 3 + index
                        : myPosition - 1 + index}
                  />
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageCard>
  ) : <div className="d-flex justify-content-center align-items-center w-100 h-100"><Spinner/></div>
};

const InfoSection = ({ icon, label, value, valueColour }) => {
  return <div className="info section">
    <img className="info-icon" src={icon} alt={label} />
    <div>
      <div className="info-label">{label}</div>
      <div className="info-value" style={{ color: valueColour }}>{value}</div>
    </div>
  </div>;
}

export const LeaderboardRow = ({ row, position, myPosition, style }) => {
  return <div className={`d-flex leaderboard-row ${myPosition === position && 'my-position'}`} style={{...style}}>
    <div className="me-2 d-flex justify-content-center align-items-center" style={{width: "10%"}}>
      <div>{position}</div>
    </div>
    <div className="me-2 d-flex justify-content-center align-items-center" style={{width: "70%"}}>{row.name}</div>
    <div className="me-2 d-flex align-items-center" style={{width: "20%"}}>
      <div style={{width: "50%"}}>

        <Coins width={"1em"} colour={myPosition === position ? "white" : null}/>
      </div>
      <div className="ms-1" style={{color: myPosition === position ? "white" : "#109539", fontWeight: 600}}>£{row.total_tenants * row.referral_amount}</div>
    </div>
  </div>
}

export const shareLink = async (referralCode, setCopied) => {
  isMobile
    ? shareOnMobile({
      text: "Hey! If you haven't got your bills sorted for next year yet, sign up here using Housr Bills!",
      url: "https://www.housrbills.co.uk/signup/lead-tenant?referral=" + referralCode,
      title: "Refer a friend",
    })
    : setCopied(true)
  await navigator.clipboard.writeText(
    "https://www.housrbills.co.uk/signup/lead-tenant?referral=" + referralCode
  );
};