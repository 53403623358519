import "./css/Blog.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import background_stripe from "../Icons/background_stripe.png";
import LatestBlogs from "./LatestBlogs";
import { Spinner } from "react-bootstrap";

const Blog = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        axios.get(`/blogs/${id}`).then((res) => {
            res.data[0].images = JSON.parse(res.data[0].images);
            setBlog(res.data[0]);
            setLoading(false);
        });
    }, [id]);

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <img
                src={background_stripe}
                className="background-stripe"
                alt="background stripe"
            />
            {loading ? (
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: "100vh" }}
                >
                    <Spinner />
                </div>
            ) : (
                <div className="blog-page">
                    <div style={{ width: "100%", marginBottom: 16 }}>
                        <button
                            className="back-button"
                            onClick={() => navigate("/blogs")}
                        >
                            Back
                        </button>
                    </div>
                    <div className="header-container">
                        {blog.images && (
                            <img
                                alt="blog"
                                src={blog.images[0]}
                                style={{
                                    aspectRatio: 1,
                                    width: "40%",
                                    borderRadius: 12,
                                    marginRight: 24,
                                    objectFit: "cover",
                                }}
                            />
                        )}
                        <div>
                            <h1 className="blog-page-title">{blog.title}</h1>
                            <div style={{ color: "#6C6C6C" }}>
                                Author: {blog.author}
                            </div>
                        </div>
                    </div>
                    <div
                        className="blog-body"
                        dangerouslySetInnerHTML={{ __html: blog.body }}
                    ></div>
                    <h2>In other news:</h2>
                    <div className="blog-body">
                        <LatestBlogs />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Blog;
